<template>
  <div
    v-if="project"
    class="rounded bg-white h-full flex flex-col shadow-md cursor-pointer overflow-hidden max-h-80"
    @click="openDetails"
  >
    <transition-group
      name="image-fade"
      mode="out-in"
      class="relative max-h-40 flex p-2 pb-0">

      <div
        v-if="projectImg"
        class="relative w-full h-full aspect-w-16 aspect-h-9 bg-gray-100 text-center"
        key="project-image"
      >
        <img
          :alt="project.name"
          :src="projectImg?.thumbnail_url"
          @load="loaded = true"
          class="object-center object-cover"
        />
      </div>
      <div
        v-else
        class="relative w-full h-full aspect-w-16 aspect-h-9 bg-gray-100 text-center"
        key="placeholder-image"
      >
        <img
          alt="Placeholder Image"
          src="@/assets/images/img-placeholder.png"
          class="absolute top-1/2 left-1/2 transform -translate-x-1/2
          -translate-y-1/2 object-center object-contain w-10/12"
        />
      </div>
    </transition-group>
    <div class="py-2 px-4 flex flex-col justify-between h-full">
      <div>
        <span
          class="text-sm xl:text-base font-semibold leading-tight my-1 line-clamp-2"
          style="line-height: 1.2"
          v-html="project.name"
        />
        <div class="text-xs text-gray-500">
          <span v-if="project.location && project.location !== ''">
            {{ project.location }},
          </span>
          <span v-if="project.countries.length > 0 && project.countries[0].name">
            {{ project.countries[0].name }}
          </span>
        </div>
      </div>
      <div class="2xl:mt-4 line-clamp-1">
        <span
          v-for="(item, index) in project.sub_categories"
          :key="'subcategory-' + index"
          class="text-xxs inline-flex items-center rounded-full bg-blue-50
          px-2.5 py-0.5 font-semibold text-blue-500 mr-1 mb-1 uppercase"
        >
          {{ item.name }}
        </span>
      </div>
    </div>

    <div class="flex mt-auto items-center justify-between gap-x-2
    bg-gray-100 border-t border-gray-300">
      <button
        class="inline-flex items-center gap-x-2 h-8 p-4
               text-sm font-semibold transition-colors text-blue-600"
        @click.stop="openDetails"
      >
        <i class="icon-eye" /> View
      </button>
      <div class="p-3">
        <div
          class="relative w-5 h-5 rounded-full bg-blue-500 text-white"
          @click.stop="toggleSelection"
        >
          <span
            class="icon-plus absolute top-1/2 left-1/2 leading-none
                  transform -translate-x-1/2 -translate-y-1/2 transition-transform"
            :class="{'rotate-45': isSelected }"
          />
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { actions, getters } from '@/store';
import { mediaTypes } from '@/constants';

const placeholderImage = require('@/assets/images/img-placeholder.png');

export default {
  name: 'ProjectCard',
  props: {
    project: {
      type: Object,
      required: true,
    },
    query: {
      type: Object,
      required: true,
    },
    sequence: {
      type: Object,
      required: true,
    },
    staticHeight: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loaded: false,
      placeholderImage,
    };
  },
  computed: {
    ...mapGetters([
      getters.contactForm.selectedProjects,
    ]),
    isSelected() {
      return this.selectedProjects.some((p) => p.id === this.project.id);
    },
    projectImg() {
      return this.project.files.filter((f) => f.type === mediaTypes.image.key)[0];
    },
  },
  methods: {
    ...mapActions([
      actions.contactForm.selectProject,
      actions.contactForm.removeProject,
    ]),
    toggleSelection() {
      if (this.isSelected) {
        this.removeFromCollection();
      } else {
        this.addToCollection();
      }
    },
    addToCollection() {
      this[actions.contactForm.selectProject](this.project);
    },
    removeFromCollection() {
      this[actions.contactForm.removeProject](this.project);
    },
    openDetails() {
      this.$router.push({
        name: 'projects.details',
        params: {
          projectId: this.project.id,
          project: this.project,
          query: this.query,
          sequence: this.sequence,
        },
      });
    },
  },
};
</script>
<style>
.image-fade-enter-active, .image-fade-leave-active {
  transition: opacity .3s;
}
.image-fade-enter, .image-fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
