import { VueOfflineStorage } from 'vue-offline';

const mutations = {
  selectProduct: 'selectProduct',
  removeProduct: 'removeProduct',
  selectProject: 'selectProject',
  removeProject: 'removeProject',
  selectAsset: 'selectAsset',
  removeAsset: 'removeAsset',
  clearProducts: 'clearProducts',
  clearProjects: 'clearProjects',
  clearAssets: 'clearAssets',
};

const actions = {
  selectProduct: 'selectProduct',
  removeProduct: 'removeProduct',
  selectProject: 'selectProject',
  removeProject: 'removeProject',
  selectAsset: 'selectAsset',
  removeAsset: 'removeAsset',
  clearProducts: 'clearProducts',
  clearProjects: 'clearProjects',
  clearAssets: 'clearAssets',
};

const getters = {
  selectedProducts: 'selectedProducts',
  selectedProjects: 'selectedProjects',
  selectedAssets: 'selectedAssets',
};

const cacheSelectedProductsKey = 'selectedProducts';
const cacheSelectedProjectsKey = 'selectedProjects';
const cacheSelectedAssetsKey = 'selectedAssets';

const addToCache = async (data, cacheKey) => {
  VueOfflineStorage.set(cacheKey, data);
};

const cachedSelectedProducts = VueOfflineStorage.get(cacheSelectedProductsKey);
const cachedSelectedProjects = VueOfflineStorage.get(cacheSelectedProjectsKey);
const cachedSelectedAssets = VueOfflineStorage.get(cacheSelectedAssetsKey);

const module = {
  state: {
    selectedProducts: cachedSelectedProducts || [],
    selectedProjects: cachedSelectedProjects || [],
    selectedAssets: cachedSelectedAssets || [],
  },
  mutations: {
    [mutations.selectProduct](state, product) {
      state.selectedProducts.push(product);
    },
    [mutations.removeProduct](state, product) {
      state.selectedProducts = state.selectedProducts.filter((p) => p.id !== product.id);
    },
    [mutations.selectProject](state, project) {
      state.selectedProjects.push(project);
    },
    [mutations.removeProject](state, project) {
      state.selectedProjects = state.selectedProjects.filter((p) => p.id !== project.id);
    },
    [mutations.selectAsset](state, asset) {
      state.selectedAssets.push(asset);
    },
    [mutations.removeAsset](state, asset) {
      state.selectedAssets = state.selectedAssets.filter((p) => p.id !== asset.id);
    },
    [mutations.clearProducts](state) {
      state.selectedProducts = [];
    },
    [mutations.clearProjects](state) {
      state.selectedProjects = [];
    },
    [mutations.clearAssets](state) {
      state.selectedAssets = [];
    },
  },
  actions: {
    async [actions.selectProduct]({ commit, state }, product) {
      commit(mutations.selectProduct, product);
      await addToCache(state.selectedProducts, cacheSelectedProductsKey);
    },
    async [actions.removeProduct]({ commit, state }, product) {
      commit(mutations.removeProduct, product);
      await addToCache(state.selectedProducts, cacheSelectedProductsKey);
    },
    async [actions.selectProject]({ commit, state }, project) {
      commit(mutations.selectProject, project);
      await addToCache(state.selectedProjects, cacheSelectedProjectsKey);
    },
    async [actions.removeProject]({ commit, state }, project) {
      commit(mutations.removeProject, project);
      await addToCache(state.selectedProjects, cacheSelectedProjectsKey);
    },
    async [actions.selectAsset]({ commit, state }, asset) {
      commit(mutations.selectAsset, asset);
      await addToCache(state.selectedAssets, cacheSelectedAssetsKey);
    },
    async [actions.removeAsset]({ commit, state }, asset) {
      commit(mutations.removeAsset, asset);
      await addToCache(state.selectedAssets, cacheSelectedAssetsKey);
    },
    async [actions.clearProducts]({ commit, state }) {
      commit(mutations.clearProducts);
      await addToCache(state.selectedProducts, cacheSelectedProductsKey);
    },
    async [actions.clearProjects]({ commit, state }) {
      commit(mutations.clearProjects);
      await addToCache(state.selectedProjects, cacheSelectedProjectsKey);
    },
    async [actions.clearAssets]({ commit, state }) {
      commit(mutations.clearAssets);
      await addToCache(state.selectedAssets, cacheSelectedAssetsKey);
    },
  },
  getters: {
    [getters.selectedProducts](state) {
      return state.selectedProducts;
    },
    [getters.selectedProjects](state) {
      return state.selectedProjects;
    },
    [getters.selectedAssets](state) {
      return state.selectedAssets;
    },
  },
};

export default {
  module,
  actions,
  getters,
};
