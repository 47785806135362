<template>
  <ul class="flex flex-wrap -my-1">
    <li
      v-for="index in itemsCount"
      :key="index"
      class="w-1/4 p-1"
    >
      <div
        role="status"
        class="rounded bg-white shadow-md animate-pulse"
      >
        <span class="sr-only">Loading...</span>

        <div class="p-2 pb-1">
          <div
            class="flex bg-gray-100 mb-3"
            style="height: 9.5rem;"
          >
            <img
              src="@/assets/images/img-skeleton.svg"
              alt=""
              class="m-auto w-12 h-12"
            />
          </div>

          <div class="h-7 xl:h-15">
            <div class="rounded-full w-3/4 h-2 xl:h-3 bg-gray-100 mb-1 xl:mb-2" />
            <div class="rounded-full w-5/6 h-2 xl:h-3 bg-gray-100 mb-1 xl:mb-2" />
            <div class="hidden xl:block rounded-full w-2/3 h-2 xl:h-3 bg-gray-100" />
          </div>
        </div>

        <div class="flex items-center justify-between gap-x-2
                    border-t border-gray p-1 xl:p-2">
          <div class="inline-flex items-center gap-x-2 h-8 py-2 px-3">
            <div class="rounded-full w-5 h-3 bg-gray-100" />
            <div class="rounded-full w-9 h-3 bg-gray-100" />
          </div>

          <div class="w-4 h-4 rounded-full bg-gray-100" />
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'ProductCardSkeleton',
  props: {
    itemsCount: {
      type: Number,
      required: true,
    },
  },
};
</script>
