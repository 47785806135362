<template>
  <div
    v-if="product"
    class="rounded bg-white h-full flex flex-col shadow-md cursor-pointer overflow-hidden max-h-80"
    @click="openDetails"
  >
    <transition-group
      name="image-fade"
      mode="out-in"
      class="relative max-h-60 flex p-2 pb-0"
    >
      <div
        v-if="productImg && loaded"
        class="relative w-full h-full aspect-w-4 aspect-h-3 text-center"
        key="product-image"
      >
        <img
          :alt="product.name"
          :src="productImg?.thumbnail_url"
          @load="loaded = true"
          class="absolute top-1/2 left-1/2 transform -translate-x-1/2
          -translate-y-1/2 object-center object-contain w-10/12"
        />
      </div>
      <div
        v-else
        class="relative w-full h-full aspect-w-4 aspect-h-3 text-center"
        key="placeholder-image"
      >
        <img
          alt="Placeholder Image"
          src="@/assets/images/img-placeholder.png"
          @load="loaded = true"
          class="absolute top-1/2 left-1/2 transform -translate-x-1/2
          -translate-y-1/2 object-center object-contain w-10/12"
        />
      </div>
    </transition-group>
    <div class="py-2 px-4 flex flex-col justify-between h-full">
      <div>
        <span
          class="text-sm xl:text-base font-semibold !leading-none
                my-1 line-clamp-2"
          :title="product.name"
        >
          {{ product.name }}
        </span>
      </div>
    </div>

    <div class="flex mt-auto items-center justify-between gap-x-2
    bg-gray-100 border-t border-gray-300">
      <button
        class="inline-flex items-center gap-x-2 h-8 p-4
               text-sm font-semibold transition-colors text-blue-600"
        @click.stop="openDetails"
      >
        <i class="icon-eye" /> View
      </button>
      <div class="p-3">
        <div
          class="relative w-5 h-5 rounded-full bg-blue-500 text-white"
          @click.stop="toggleSelection"
        >
          <span
            class="icon-plus absolute top-1/2 left-1/2 leading-none
                  transform -translate-x-1/2 -translate-y-1/2 transition-transform"
            :class="{'rotate-45': isSelected }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { actions, getters } from '@/store';
import { mediaTypes } from '@/constants';

const placeholderImage = require('@/assets/images/img-placeholder.png');

export default {
  name: 'ProductCard',
  props: {
    product: {
      type: Object,
      required: true,
    },
    query: {
      type: Object,
      required: true,
    },
    sequence: {
      type: Object,
      required: true,
    },
    staticHeight: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loaded: false,
      placeholderImage,
    };
  },
  computed: {
    ...mapGetters([
      getters.contactForm.selectedProducts,
    ]),
    isSelected() {
      return this.selectedProducts.some((p) => p.id === this.product.id);
    },
    productImg() {
      return this.product.files.filter((f) => f.type === mediaTypes.image.key)[0];
    },
  },
  methods: {
    ...mapActions([
      actions.contactForm.selectProduct,
      actions.contactForm.removeProduct,
    ]),
    toggleSelection() {
      if (this.isSelected) {
        this.removeFromCollection();
      } else {
        this.addToCollection();
      }
    },
    addToCollection() {
      this[actions.contactForm.selectProduct](this.product);
    },
    removeFromCollection() {
      this[actions.contactForm.removeProduct](this.product);
    },
    openDetails() {
      this.$router.push({
        name: 'products.details',
        params: {
          productId: this.product.id,
          product: this.product,
          query: this.query,
          sequence: this.sequence,
        },
      });
    },
  },
};
</script>
<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
  transition-delay: 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
