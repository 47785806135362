<!-- eslint-disable max-len -->
<template>
  <footer
    class="grow-0 relative w-full z-50 px-8"
    style="flex: 0 0 auto"
  >
    <nav class="flex items-center justify-center gap-0.5 overflow-hidden pb-6">

      <router-link
        :to="{ name: products.index }"
        :class="{
          'bg-blue-100 text-blue-600': $route.path === '/products',
          'bg-white hover:bg-blue-50': $route.path !== '/products',

        }"
        class="
        px-12 py-3 flex items-center gap-2
        justify-center transition-colors shadow-md rounded-l-md
        "
      >
        <img class="w-8 xl:w-10" src="@/assets/images/mapei-structure.png" alt="Products">
        <span class="text-blue-600 font-medium 2xl:text-lg">Products</span>
      </router-link>

      <router-link
        :to="{ name: projects.index }"
        :class="{
          'bg-blue-100 text-blue-600': $route.path === '/projects',
          'bg-white hover:bg-blue-50': $route.path !== '/projects',
        }"
        class="px-12 py-3 flex items-center gap-2 justify-center transition-colors shadow-md"
      >
        <img class="w-8 xl:w-10" src="@/assets/images/mapei-lab.png" alt="Projects">
        <span class="text-blue-600 font-medium 2xl:text-lg">Projects</span>
      </router-link>

      <router-link
        :to="{ name: aboutUs.index }"
        :class="{
          'bg-blue-100 text-blue-600': $route.path === '/about-us',
          'bg-white hover:bg-blue-50': $route.path !== '/about-us',
        }"
        class="px-12 py-3 flex items-center gap-2
        justify-center transition-colors shadow-md rounded-r-md"
      >
        <img class="w-8 xl:w-10" src="@/assets/images/mapei-hand.png" alt="About Us">
        <span class="text-blue-600 font-medium 2xl:text-lg">About</span>
      </router-link>
    </nav>
  </footer>
</template>

<script>
// import { mapGetters } from 'vuex';
import {
  home,
  products,
  projects,
  aboutUs,
  myCollection,
} from '@/constants/route-names';
// import { getters } from '@/store';

export default {
  name: 'FooterLayout',
  data() {
    return {
      products,
      projects,
      aboutUs,
      myCollection,
      firstLoad: true,
    };
  },
  computed: {
    // ...mapGetters([
    //   getters.contactForm.selectedProducts,
    //   getters.contactForm.selectedProjects,
    //   getters.contactForm.selectedAssets,
    // ]),
    isHomePage() {
      return this.$route.name === home.index;
    },
    // selectedCount() {
    //   return (this.selectedProducts.length
    //   + this.selectedProjects.length
    //   + this.selectedAssets.length);
    // },
  },
  watch: {
    $route(to, from) {
      this.firstLoad = from.name === null;
    },
  },
  methods: {
    goBack() {
      this.$router.push({
        name: home.index,
      });
    },
  },
};
</script>
