<!-- eslint-disable max-len -->
<template>
  <div
    v-if="files.length > 0"
    class="w-full rounded border border-black border-opacity-5 swiper-no-swiping"
    :class="{
      'bg-gray-100': !isSelected,
      'bg-blue-light': isSelected
    }"
  >

    <div class="w-full flex items-center justify-between gap-x-4 pt-2 pb-4 px-4">
      <div class="flex items-center gap-x-4">
        <img
          src="@/assets/images/icons/document.svg"
          alt=""
        />

        <div
          class="text-sm 2xl:text-base font-semibold"
          style="min-width: 12vw; max-width: 15vw;"
          :title="assetName"
        >
          {{ assetName }}
        </div>
      </div>
      <div class="flex items-center justify-end gap-x-4">
        <!-- <div v-if="languageOptions.length > 0">
          <v-select
            v-model="model.language"
            :options="languageOptions"
            :searchable="false"
            placeholder="Language"
            class="v-select--secondary sm"
          />
        </div> -->
        <!-- <div v-if="versionOptions.length > 0">
          <v-select
            v-model="model.version"
            :options="versionOptions"
            :searchable="false"
            placeholder="Version"
            class="v-select--secondary sm"
          />
        </div> -->
        <div v-if="languageOptions.length > 0">
          <span class="block text-xxs uppercase text-gray-500 font-semibold">
            Language
          </span>
          <select
            name="language"
            v-model="model.language"
            class="border border-gray-300 rounded-md p-1  w-full"
          >
            <option
              v-for="(option, i) in languageOptions"
              :key="'language-' + i"
              v-html="option"
            />
          </select>
        </div>
        <div v-if="versionOptions.length > 0">
          <span class="block text-xxs uppercase text-gray-500 font-semibold">
            Version
          </span>
          <select
            name="version"
            v-model="model.version"
            class="border border-gray-300 rounded-md p-1 w-full"
          >
            <option
              v-for="(option, i) in versionOptions"
              :key="'version-' + i"
              :value="option"
              v-html="option"
            />
          </select>
        </div>
      </div>
    </div>

    <div class="flex items-center justify-between px-4 py-3 border-t border-black border-opacity-5">

        <div
          @click="openAsset"
          class="flex items-center gap-2 text-xs xl:text-sm
          font-medium hover:text-blue-600 text-blue-500 transition-opacity"
          :class="{
            'opacity-20 pointer-events-none': !canAction,
            'cursor-pointer': canAction
          }"
        >
          <i class="icon-eye" />
          <span>View</span>
        </div>

        <!-- <t-button
          v-if="!canAction"
          variant="breadcrumb"
          disabled
          class="flex gap-x-2 shadow-none text-xs xl:text-sm tracking-normal"
        >
          <i class="icon-eye" />
          <span>View</span>
        </t-button>
        <t-button
          v-else
          variant="breadcrumb"
          class="flex gap-x-2 shadow-none text-xs xl:text-sm tracking-normal"
          @click.prevent="openAsset"
          download
        >
          <i class="icon-eye" />
          <span>View</span>
        </t-button> -->

        <div
          v-if="!isSelected"
          :disabled="!canAction"
          @click="addToCollection"
          class="flex items-center gap-2 text-xs xl:text-sm cursor-pointer
          font-medium hover:text-blue-600 text-blue-500 transition-opacity"
          :class="{
            'pointer-events-none opacity-30': !canAction,
            'cursor-pointer': canAction
          }"
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
          </svg>
          <span>Add to Collection</span>
        </div>
        <div
          v-else
          :disabled="!canAction"
          @click="removeFromCollection"
          class="flex items-center gap-2 text-xs xl:text-sm cursor-pointer
          font-medium hover:text-blue-600 text-blue-500"
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m6.75 12H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
          </svg>
          <span>Remove</span>

        <!-- <button
          v-if="!isSelected"
          class="inline-flex items-center
                text-xs xl:text-base font-medium text-blue
                transition-colors hover:text-blue-dark
                disabled:opacity-50 disabled:pointer-events-none"
          :disabled="!canAction"
          @click="addToCollection"
        >
          <i
            class="inline-flex items-center justify-center mr-2 w-4 h-4
                  border-2 border-blue rounded-full
                bg-blue opacity-40 font-medium text-white not-italic icon-plus"
          />
          <span>Add to Collection</span>
        </button> -->

        <!-- <button
          v-else
          class="inline-flex items-center
                  text-xs xl:text-base font-medium text-blue
                  transition-colors hover:text-blue-dark
                disabled:opacity-50 disabled:pointer-events-none"
          :disabled="!canAction"
          @click="removeFromCollection"
        >
          <i
            class="inline-flex items-center justify-center mr-2 w-4 h-4
                  transform rotate-45
                  border-2 border-blue rounded-full
                bg-blue opacity-40 font-medium text-white not-italic icon-plus"
          />
          <span>On your Basket</span>
        </button> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mediaTypes } from '@/constants';
import { mapActions, mapGetters } from 'vuex';
import { actions, getters } from '@/store';

export default {
  name: 'AssetCard',
  props: {
    files: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      mediaTypes,
      model: {
        language: null,
        version: null,
      },
    };
  },
  computed: {
    ...mapGetters([
      getters.contactForm.selectedAssets,
    ]),
    isSelected() {
      return this.selectedAssets.some((a) => a.id === this.selectedAsset?.id);
    },
    selectedAsset() {
      return this.files
        .filter((f) => f.language === this.model.language)
        .filter((f) => f.version === this.model.version)[0] || null;
    },
    assetType() {
      return this.files[0].type || '';
    },
    assetName() {
      return mediaTypes[this.assetType].name || '';
    },
    languageOptions() {
      const filtered = this.model.version
        ? this.files
          .filter((f) => f.version === this.model.version)
          .map((f) => f.language).filter((l) => l)
        : this.files
          .map((f) => f.language).filter((l) => l);

      return Array.from(new Set(filtered));
    },
    versionOptions() {
      const filtered = this.model.language
        ? this.files
          .filter((f) => f.language === this.model.language)
          .map((f) => f.version)
          .filter((l) => l)
        : this.files
          .map((f) => f.version)
          .filter((l) => l);

      return Array.from(new Set(filtered));
    },
    canAction() {
      return !!this.selectedAsset;
    },
  },
  methods: {
    ...mapActions([
      actions.contactForm.selectAsset,
      actions.contactForm.removeAsset,
      actions.assets.setSelectedAsset,
    ]),
    addToCollection() {
      this[actions.contactForm.selectAsset](this.selectedAsset);
    },
    removeFromCollection() {
      this[actions.contactForm.removeAsset](this.selectedAsset);
    },
    openAsset() {
      this[actions.assets.setSelectedAsset](this.selectedAsset);
    },
  },
};
</script>
