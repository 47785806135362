var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"grow-0 relative w-full z-50 px-8",staticStyle:{"flex":"0 0 auto"}},[_c('nav',{staticClass:"flex items-center justify-center gap-0.5 overflow-hidden pb-6"},[_c('router-link',{staticClass:"px-12 py-3 flex items-center gap-2 justify-center transition-colors shadow-md rounded-l-md",class:{
        'bg-blue-100 text-blue-600': _vm.$route.path === '/products',
        'bg-white hover:bg-blue-50': _vm.$route.path !== '/products',

      },attrs:{"to":{ name: _vm.products.index }}},[_c('img',{staticClass:"w-8 xl:w-10",attrs:{"src":require("@/assets/images/mapei-structure.png"),"alt":"Products"}}),_c('span',{staticClass:"text-blue-600 font-medium 2xl:text-lg"},[_vm._v("Products")])]),_c('router-link',{staticClass:"px-12 py-3 flex items-center gap-2 justify-center transition-colors shadow-md",class:{
        'bg-blue-100 text-blue-600': _vm.$route.path === '/projects',
        'bg-white hover:bg-blue-50': _vm.$route.path !== '/projects',
      },attrs:{"to":{ name: _vm.projects.index }}},[_c('img',{staticClass:"w-8 xl:w-10",attrs:{"src":require("@/assets/images/mapei-lab.png"),"alt":"Projects"}}),_c('span',{staticClass:"text-blue-600 font-medium 2xl:text-lg"},[_vm._v("Projects")])]),_c('router-link',{staticClass:"px-12 py-3 flex items-center gap-2 justify-center transition-colors shadow-md rounded-r-md",class:{
        'bg-blue-100 text-blue-600': _vm.$route.path === '/about-us',
        'bg-white hover:bg-blue-50': _vm.$route.path !== '/about-us',
      },attrs:{"to":{ name: _vm.aboutUs.index }}},[_c('img',{staticClass:"w-8 xl:w-10",attrs:{"src":require("@/assets/images/mapei-hand.png"),"alt":"About Us"}}),_c('span',{staticClass:"text-blue-600 font-medium 2xl:text-lg"},[_vm._v("About")])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }