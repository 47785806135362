import { products } from '@/constants/route-names';

const component = () => import('./products.view.vue');
const componentDetails = () => import('./details/products-details.view.vue');

export default [
  {
    path: '/products',
    name: products.index,
    component,
    meta: {
      title: 'Products',
      transition: 'fade',
    },
  },
  {
    path: '/products/:productId',
    name: products.details,
    component: componentDetails,
    meta: {
      title: 'Products',
      transition: 'fade',
    },
  },
];
