<!-- eslint-disable max-len -->
<template>
  <div class="relative grid grid-cols-1 grid-row-3 gap-0.5 rounded-lg overflow-hidden shadow-md">

    <div
      class="relative p-4 pt-3 w-16 h-16 bg-white hover:bg-blue-50 cursor-pointer text-center transition-colors"
      style="border-radius: 0.5rem 0 0 0"
      @click="zoomIn()"
    >
      <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col gap-1.5 justify-center items-center">
        <img
          src="@/assets/images/icons/zoom-in.svg"
          alt="Zoom In"
          class="w-6 h-6 inline-block"
        >
        <span class="text-blue-600 text-xxs uppercase font-semibold leading-none">
          Zoom In
        </span>
      </div>
    </div>

    <div
      class="relative p-4 pt-3 w-16 h-16 bg-white hover:bg-blue-50 cursor-pointer text-center transition-colors"
      @click="zoomOut()"
    >
      <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col gap-1.5 justify-center items-center">
        <img
          src="@/assets/images/icons/zoom-out.svg"
          alt="Zoom Out"
          class="w-6 h-6 inline-block"
        >
        <span class="text-blue-600 text-xxs uppercase font-semibold  leading-none">
          Zoom Out
        </span>
      </div>
    </div>

    <div
      class="relative p-4 pt-3 w-16 h-16 bg-white hover:bg-blue-50 cursor-pointer text-center transition-colors"
      style="border-radius: 0 0 0 0.5rem"
      @click="reset()"
    >
      <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col gap-1.5 justify-center items-center -mt-0.5">
        <img
          src="@/assets/images/icons/reset.svg"
          alt="Reset"
          class="w-8 h-8 inline-block"
        >
        <span class="text-blue-600 text-xxs uppercase font-semibold leading-none">
          Reset
        </span>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'ModelControls',
  methods: {
    zoomIn() {
      this.$emit('zoom-in');
    },
    zoomOut() {
      this.$emit('zoom-out');
    },
    reset() {
      this.$emit('reset');
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
