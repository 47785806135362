import Vue from 'vue';
import VueTailwind from 'vue-tailwind';
import TButton from 'vue-tailwind/dist/t-button';
import TInput from 'vue-tailwind/dist/t-input';
import TSelect from 'vue-tailwind/dist/t-select';
import TModal from 'vue-tailwind/dist/t-modal';
import Paginate from 'vuejs-paginate';
import Meta from 'vue-meta';
import Vue2TouchEvents from 'vue2-touch-events';

import VSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

import {
  required,
  email,
} from 'vee-validate/dist/rules';
import {
  extend,
  localize,
  ValidationObserver,
  ValidationProvider,
} from 'vee-validate';

import { Navigation, Pagination } from 'swiper';
import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2';
import * as customRules from '@/utils/validation-rules/index';

import { ContentLoader } from '@/components';

import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';

const settings = {
  't-button': {
    component: TButton,
    props: {
      fixedClasses: 'cursor-pointer font-medium transition-colors disabled:opacity-30 disabled:pointer-events-none',
      classes: 'px-3 xl:px-6 py-2 xl:py-3 bg-white shadow-md text-sm xl:text-base text-blue-500 hover:bg-blue-100 hover:text-white focus:bg-blue focus:text-white',
      variants: {
        secondary: 'px-3 xl:px-6 py-2 xl:py-3 bg-blue-500 text-sm xl:text-xl-base text-white hover:bg-white hover:text-blue-500 focus:bg-white focus:text-blue',
        outline: 'border border-blue-500 rounded-sm px-3 py-1 bg-white text-sm xl:text-xl text-blue-400 blue hover:bg-blue-100 hover:text-white focus:bg-blue-100 focus:text-white',
        home: 'box-border px-2 lg:px-4 xl:px-6 py-2 xl:py-3 text-xs lg:text-sm 2xl:text-base text-white bg-blue-500 hover:bg-blue-600 focus:bg-white focus:text-blue-500',
        nav: 'rounded px-2 lg:px-4 xl:px-6 py-2 xl:py-3 bg-white shadow-md text-xs lg:text-sm 2xl:text-base  hover:text-blue-100 whitespace-nowrap box-border',
        breadcrumb: 'rounded px-3 xl:px-6 py-2 xl:py-3 bg-tint-100 shadow text-base text-blue hover:bg-blue-100 hover:font-bold hover:text-white',
        error: 'px-3 xl:px-6 py-2 xl:py-3 bg-red-600 text-white',
        success: 'px-3 xl:px-6 py-2 xl:py-3 bg-green text-white',
        link: 'text-blue-500 underline hover:text-blue-600',
      },
    },
  },
  't-input': {
    component: TInput,
    props: {
      fixedClasses: 'appearance-none rounded-none block w-full px-3 py-2 2xl:py-3 border border-gray-200 rounded placeholder-gray-500 transition duration-300',
      classes: ' hover:border-blue-300 focus:outline-none focus:border-blue-500',
    },
  },
  't-select': {
    component: TSelect,
    props: {
      fixedClasses: 'block w-full border-2 border-gray rounded pl-2 pr-6 py-2 transition duration-300 ease-in-out shadow-sm focus:border-blue-500 disabled:opacity-50 disabled:cursor-not-allowed',
      classes: 'text-sm leading-none text-gray-dark placeholder-gray-dark bg-white',
      variants: {
        danger: 'border-red-300 bg-red-50 placeholder-red-200 text-red-900',
        success: 'border-green-300 bg-green-50 placeholder-gray-400 text-green-900',
      },
    },
  },
  't-modal': {
    component: TModal,
    props: {
      fixedClasses: {
        overlay: 'fixed z-110 overflow-auto scrolling-touch left-0 top-0 bottom-0 right-0 w-screen h-full fixed bg-opacity-50',
        wrapper: 'w-full h-full relative mx-auto z-110 px-3 py-12',
        modal: 'overflow-visible w-full h-full relative rounded',
        body: 'p-3 w-full h-full ',
        header: 'border-b p-3 rounded-t',
        footer: ' p-3 rounded-b',
        close: 'flex items-center justify-center rounded-full absolute right-0 top-0 -m-3 h-8 w-8 transition duration-100 ease-in-out focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50',
      },
      classes: {
        overlay: 'bg-black',
        wrapper: '',
        modal: 'bg-white shadow',
        body: 'p-3',
        header: 'border-gray-100',
        footer: 'bg-gray-100',
        close: 'bg-gray-100 text-gray-600 hover:bg-gray-200',
        closeIcon: 'fill-current h-4 w-4',
        overlayEnterClass: 'opacity-0',
        overlayEnterActiveClass: 'transition ease-out duration-100',
        overlayEnterToClass: 'opacity-100',
        overlayLeaveClass: 'opacity-100',
        overlayLeaveActiveClass: 'transition ease-in duration-75',
        overlayLeaveToClass: 'opacity-0',
      },
      variants: {
        danger: {
          overlay: 'bg-red-100',
          header: 'border-red-50 text-red-700',
          close: 'bg-red-50 text-red-700 hover:bg-red-200 border-red-100 border',
          modal: 'bg-white border border-red-100 shadow-lg',
          footer: 'bg-red-50',
        },
      },
    },
  },
};

Vue.use(VueTailwind, settings);

Vue.use(Meta, {
  refreshOnceOnNavigation: true,
});

Vue.use(Vue2TouchEvents);

SwiperCore.use([Navigation, Pagination]);

localize({
  en: {
    messages: {
      email: 'The {_field_} field must be a valid email',
      required: 'The {_field_} field is required',
    },
  },
});
localize('en');

extend('required', required);
extend('email', email);
extend(customRules.phone.name, {
  ...customRules.phone.schema,
});

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('Paginate', Paginate);
Vue.component('VSelect', VSelect);
Vue.component('Swiper', Swiper);
Vue.component('SwiperSlide', SwiperSlide);
Vue.component('ContentLoader', ContentLoader);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
