import Vue from 'vue';
import VueRouter from 'vue-router';
import { routes as homeRoutes } from '@/views/home';
import { routes as myCollectionRoutes } from '@/views/my-collection';
import { routes as aboutUsRoutes } from '@/views/about-us';
import { routes as projectsRoutes } from '@/views/projects';
import { routes as productsRoutes } from '@/views/products';
import { routes as notFoundRoutes } from '@/views/not-found';
import { routeNames } from '@/constants';

Vue.use(VueRouter);

const routes = [
  ...homeRoutes,
  ...myCollectionRoutes,
  ...aboutUsRoutes,
  ...projectsRoutes,
  ...productsRoutes,
  ...notFoundRoutes,
];

const router = new VueRouter({
  mode: 'history',
  routes,
  base: process.env.VUE_APP_BASE_URL,
});

router.beforeEach((to, from, next) => {
  if (from.name
    && to.name !== routeNames.home.index
    && from.name !== routeNames.home.index
    && (from.query.categoryId
      && +to.query.categoryId !== +from.query.categoryId)) {
    // eslint-disable-next-line no-param-reassign
    to.query.categoryId = from.query.categoryId;
    next(to);
  } else {
    next();
  }
});

export default router;
