<!-- eslint-disable max-len -->
<template>
  <header
    class="relative bg-white grow-0 p-4 lg:px-8 2xl:p-8 flex items-center justify-between z-100 shadow"
    style="flex: 0 0 auto"
  >
    <router-link
      to="/"
      class="flex items-center gap-1 bg-blue-50 hover:bg-blue-100 rounded-md p-1.5 pr-4 transition-colors cursor-pointer"
    >
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" class="w-5 h-5 text-blue-500">
        <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
      </svg>

      <span class="text-blue-600 font-medium text-sm 2xl:text-lg leading-none">
        Back
      </span>
    </router-link>

    <router-link
      to="/"
      class="flex items-center gap-4 hover:opacity-50 transition-opacity"
    >
      <img
        src="@/assets/images/logo-sm.png"
        alt="MAPEI"
        class="w-8 xl:w-10 2xl:-12"
      />
      <span class="font-bold text-blue-500 text-xl xl:text-2xl 2xl:text-3xl">MAPEI WORLD</span>
    </router-link>

    <router-link
      to="/my-collection"
      class="relative flex items-center gap-2 2xl:gap-4 bg-blue-50 hover:bg-blue-100 rounded-md p-1.5 pr-2 pl-4 transition-colors"
    >
      <span class="text-blue-600 font-medium text-sm 2xl:text-lg pt-0.5">
        My Collection
      </span>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-blue-500">
        <path stroke-linecap="round" stroke-linejoin="round" d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75" />
      </svg>
      <span
        v-show="selectedCount && selectedCount > 0"
        class="absolute text-xxs 2xl:text-xs top-0 right-0 transform translate-x-1/2 -translate-y-1/2
              rounded-full w-4 2xl:w-5 h-4 2xl:h-5 flex items-center justify-center bg-red-500 text-white font-semibold leading-none z-100"
      >
        {{ selectedCount }}
      </span>

    </router-link>

  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import { getters } from '@/store';

export default {
  name: 'AppHeader',
  computed: {
    ...mapGetters([
      getters.contactForm.selectedProducts,
      getters.contactForm.selectedProjects,
      getters.contactForm.selectedAssets,
    ]),
    selectedCount() {
      return (this.selectedProducts.length
      + this.selectedProjects.length
      + this.selectedAssets.length);
    },
  },
};
</script>
