import { projects } from '@/constants/route-names';

const component = () => import('./projects.view.vue');
const componentDetails = () => import('./details/projects-single.view.vue');

export default [
  {
    path: '/projects',
    name: projects.index,
    component,
    meta: {
      title: 'Projects',
      transition: 'fade',
    },
  },
  {
    path: '/projects/:projectId',
    name: projects.details,
    component: componentDetails,
    meta: {
      title: 'Project',
      transition: 'fade',
    },
  },
];
