<template>
  <div class="relative">
    <div class="flex mb-12">
      <div class="h-65vh 2xl:h-60vh w-4/12 2xl:w-3/12 pr-4 2xl:pr-0">
        <div class="w-full h-full p-4 bg-white animate-pulse">
          <div class="flex h-56 xl:h-72 mb-4 bg-gray-100">
            <img
              src="@/assets/images/img-skeleton.svg"
              alt=""
              class="m-auto w-12 h-12"
            />
          </div>

          <div class="text-center mb-3">
            <div class="inline-block rounded-full w-3/4 h-4 bg-gray-100 mb-1 xl:mb-2" />
          </div>

          <div class="flex flex-wrap justify-center gap-1">
            <div class="rounded-full w-1/3 h-5 bg-gray-100 mb-1 xl:mb-2" />
            <div class="rounded-full w-1/3 h-5 bg-gray-100 mb-1 xl:mb-2" />
            <div class="rounded-full w-1/3 h-5 bg-gray-100 mb-1 xl:mb-2" />
          </div>
        </div>
      </div>

      <div class="h-65vh 2xl:h-60vh w-8/12 2xl:w-9/12 2xl:pl-11">
        <div class="w-full h-full bg-white animate-pulse">
          <div class="flex">
            <div class="py-3 xl:py-4 px-6 xl:px-8
                      bg-gray-100 text-sm xl:text-base font-medium
                        whitespace-nowrap shadow-md transition-colors"
            >
              <div class="rounded-full w-16 h-4 bg-gray-100" />
            </div>

            <div class="py-3 xl:py-4 px-6 xl:px-8
                      bg-gray-100 text-sm xl:text-base font-medium
                        whitespace-nowrap shadow-md transition-colors"
            >
              <div class="rounded-full w-20 h-4 bg-gray-100" />
            </div>

            <div class="py-3 xl:py-4 px-6 xl:px-8
                      bg-gray-100 text-sm xl:text-base font-medium
                        whitespace-nowrap shadow-md transition-colors"
            >
              <div class="rounded-full w-16 h-4 bg-gray-100" />
            </div>
          </div>

          <div class="relative z-10 w-full p-5 xl:p-8 bg-white">
            <div class="inline-block rounded-full w-1/2 h-4 bg-gray-100 mb-3 xl:mb-5" />

            <div class="flex flex-wrap gap-2 w-3/4">
              <div class="inline-block rounded-full w-20 h-4 bg-gray-100" />
              <div class="inline-block rounded-full w-24 h-4 bg-gray-100" />
              <div class="inline-block rounded-full w-16 h-4 bg-gray-100" />
              <div class="inline-block rounded-full w-32 h-4 bg-gray-100" />
              <div class="inline-block rounded-full w-36 h-4 bg-gray-100" />
              <div class="inline-block rounded-full w-20 h-4 bg-gray-100" />
              <div class="inline-block rounded-full w-24 h-4 bg-gray-100" />
              <div class="inline-block rounded-full w-10 h-4 bg-gray-100" />
              <div class="inline-block rounded-full w-48 h-4 bg-gray-100" />
              <div class="inline-block rounded-full w-56 h-4 bg-gray-100" />
              <br>
              <br>
              <div class="inline-block rounded-full w-24 h-4 bg-gray-100" />
              <div class="inline-block rounded-full w-16 h-4 bg-gray-100" />
              <div class="inline-block rounded-full w-32 h-4 bg-gray-100" />
              <div class="inline-block rounded-full w-36 h-4 bg-gray-100" />
              <div class="inline-block rounded-full w-20 h-4 bg-gray-100" />
              <div class="inline-block rounded-full w-24 h-4 bg-gray-100" />
              <div class="inline-block rounded-full w-10 h-4 bg-gray-100" />
              <div class="inline-block rounded-full w-16 h-4 bg-gray-100" />
              <div class="inline-block rounded-full w-32 h-4 bg-gray-100" />
              <div class="inline-block rounded-full w-36 h-4 bg-gray-100" />
              <div class="inline-block rounded-full w-20 h-4 bg-gray-100" />
              <div class="inline-block rounded-full w-24 h-4 bg-gray-100" />
              <div class="inline-block rounded-full w-10 h-4 bg-gray-100" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductDetailsSkeleton',
};
</script>
