/* eslint-disable no-shadow */
const state = {
  show: false,
  image: '',
  alt: '',
};

const mutations = {
  openLightbox(state, payload) {
    state.image = payload.image;
    state.alt = payload.alt;
    state.show = true;
  },
  closeLightbox(state) {
    state.show = false;
    state.image = '';
    state.alt = '';
  },
};

export default {
  state,
  mutations,
};
