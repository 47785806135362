import { VueOfflineStorage } from 'vue-offline';

const mutations = {
  loadContent: 'LOAD_CONTENT',
  setInitialProductQuery: 'setInitialProductQuery',
  setInitialProjectQuery: 'setInitialProjectQuery',
};

const actions = {
  loadContent: 'LOAD_CONTENT',
  setInitialProductQuery: 'setInitialProductQuery',
  setInitialProjectQuery: 'setInitialProjectQuery',
};

const getters = {
  applicationTypes: 'applicationTypes',
  countries: 'countries',
  categories: 'categories',
  subCategories: 'subCategories',
  productLines: 'productLines',
  initialProductQuery: 'initialProductQuery',
  initialProjectQuery: 'initialProjectQuery',
};

const contentKey = 'content';
const initialProductQueryKey = 'initialProductQuery';
const initialProjectQueryKey = 'initialProjectQuery';

const addToCache = async (data, cacheKey) => {
  VueOfflineStorage.set(cacheKey, data);
};

const getContent = async () => {
  const responses = await Promise.all([
    fetch(`${process.env.VUE_APP_API_BASE_PATH}/countries`),
    fetch(`${process.env.VUE_APP_API_BASE_PATH}/product-lines`),
    fetch(`${process.env.VUE_APP_API_BASE_PATH}/categories`),
    fetch(`${process.env.VUE_APP_API_BASE_PATH}/sub-categories`),
    fetch(`${process.env.VUE_APP_API_BASE_PATH}/application-types`),
  ]);

  const respJson = await Promise.all(responses.map((r) => r.json()));
  const respData = respJson.map((r) => r.data);

  const [
    countries,
    productLines,
    categories,
    subCategories,
    applicationTypes,
  ] = respData;

  return {
    countries,
    productLines,
    categories,
    subCategories,
    applicationTypes,
  };
};

const cachedContent = VueOfflineStorage.get(contentKey);
const cachedInitialProductQuery = VueOfflineStorage.get(initialProductQueryKey);
const cachedInitialProjectQuery = VueOfflineStorage.get(initialProjectQueryKey);

const module = {
  state: {
    content: cachedContent || {},
    initialProductQuery: cachedInitialProductQuery || {},
    initialProjectQuery: cachedInitialProjectQuery || {},
  },
  getters: {
    [getters.applicationTypes](state) {
      return state.content.applicationTypes;
    },
    [getters.countries](state) {
      return state.content.countries;
    },
    [getters.categories](state) {
      return state.content.categories;
    },
    [getters.subCategories](state) {
      return state.content.subCategories;
    },
    [getters.productLines](state) {
      return state.content.productLines;
    },
    [getters.initialProductQuery](state) {
      return state.initialProductQuery;
    },
    [getters.initialProjectQuery](state) {
      return state.initialProjectQuery;
    },
  },
  mutations: {
    [mutations.loadContent](state, content) {
      state.content = content;
    },
    [mutations.setInitialProductQuery](state, initialProductQuery) {
      state.initialProductQuery = initialProductQuery;
    },
    [mutations.setInitialProjectQuery](state, initialProjectQuery) {
      state.initialProjectQuery = initialProjectQuery;
    },
  },
  actions: {
    async [actions.loadContent]({ commit }) {
      try {
        const data = await getContent();
        commit(mutations.loadContent, data);
        await addToCache(data, contentKey);
      } catch (error) {
        console.error(error);
      }
    },
    async [actions.setInitialProductQuery]({ commit }, initialProductQuery) {
      try {
        commit(mutations.setInitialProductQuery, initialProductQuery);
        await addToCache(initialProductQuery, initialProductQueryKey);
      } catch (error) {
        console.error(error);
      }
    },
    async [actions.setInitialProjectQuery]({ commit }, initialProjectQuery) {
      try {
        commit(mutations.setInitialProjectQuery, initialProjectQuery);
        await addToCache(initialProjectQuery, initialProjectQueryKey);
      } catch (error) {
        console.error(error);
      }
    },
  },
};

export default {
  module,
  actions,
  getters,
};
