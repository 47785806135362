const mutations = {
  setSelectedAsset: 'setSelectedAsset',
  removeSelectedAsset: 'removeSelectedAsset',
};

const actions = {
  setSelectedAsset: 'setSelectedAsset',
  removeSelectedAsset: 'removeSelectedAsset',
};

const getters = {
  selectedAsset: 'selectedAsset',
};

const module = {
  state: {
    selectedAsset: null,
  },
  getters: {
    [getters.selectedAsset](state) {
      return state.selectedAsset;
    },
  },
  mutations: {
    [mutations.setSelectedAsset](state, asset) {
      state.selectedAsset = asset;
    },
    [mutations.removeSelectedAsset](state) {
      state.selectedAsset = null;
    },
  },
  actions: {
    [actions.setSelectedAsset]({ commit }, asset) {
      commit(mutations.setSelectedAsset, asset);
    },
    [actions.removeSelectedAsset]({ commit }) {
      commit(mutations.removeSelectedAsset);
    },
  },
};

export default {
  module,
  actions,
  getters,
};
