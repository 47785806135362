import { aboutUs } from '@/constants/route-names';

const component = () => import('./about-us.view.vue');

export default [
  {
    path: '/about-us',
    name: aboutUs.index,
    component,
    meta: {
      title: 'About Us',
      transition: 'slide-fade',
    },
  },
];
