<!-- eslint-disable max-len -->
<template>
  <vue-tel-input
    v-model="phone"
    :inputOptions="{ showDialCode: true }"
    class="py-1 border border-gray-200 focus-within:outline-nonefocus-within:border-blue-500 focus-within:shadow-none rounded hover:border-blue-300 transition-colors"
    @input="onInput"
  />
</template>

<script>
import { VueTelInput } from 'vue-tel-input';

export default {
  name: '',
  components: { VueTelInput },
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      phone: '',
    };
  },
  watch: {
    value: {
      handler(val) {
        if (!val) return;
        this.phone = `+${val}`;
      },
      immediate: true,
    },
  },
  methods: {
    onInput(_, phoneObject) {
      if (!phoneObject?.countryCallingCode && !phoneObject?.nationalNumber) return;
      this.$emit('input', `${phoneObject.countryCallingCode}${phoneObject.nationalNumber}`);
    },
  },
};
</script>

<style src="vue-tel-input/dist/vue-tel-input.css"></style>
