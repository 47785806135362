export default {
  image: {
    key: 'image',
    name: 'Image',
  },
  video: {
    key: 'video',
    name: 'Video',
  },
  pdf: {
    key: 'pdf',
    name: 'PDF',
  },
  technical_sheet: {
    key: 'technical_sheet',
    name: 'Technical Sheet',
  },
  security_sheet: {
    key: 'security_sheet',
    name: 'Security Sheet',
  },
};
