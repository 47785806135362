import Vue from 'vue';
import Vuex from 'vuex';
import contentModule from './modules/content.module';
import contactFormModule from './modules/contact-form.module';
import assetsModule from './modules/assets.module';
import lightbox from './modules/lightbox';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    lightbox,
    content: contentModule.module,
    contactForm: contactFormModule.module,
    assets: assetsModule.module,
  },
});

const actions = {
  content: contentModule.actions,
  contactForm: contactFormModule.actions,
  assets: assetsModule.actions,
};

const getters = {
  content: contentModule.getters,
  contactForm: contactFormModule.getters,
  assets: assetsModule.getters,
};

export {
  actions,
  getters,
};
