<!-- eslint-disable max-len -->
<template>
  <div
    v-if="show"
    class="fixed bg-blue-800 bg-opacity-70 py-20 px-20 top-0 left-0 right-0 bottom-0 w-screen h-screen"
    :class="{
      'pointer-events-none':!show
    }"
    style="z-index: 9999;"
  >
    <div
      class="absolute top-8 left-8 right-8 bottom-8 bg-center bg-contain bg-no-repeat"
      :style="'background-image: url(' + image + ')'"
    >
      <!-- <img
        class="w-full h-full object-center object-contain"
        :src="image"
        :alt="alt"
      > -->
      <div
        @click="close"
        class="cursor-pointer absolute p-1 w-12 h-12 text-5xl flex items-center justify-center bottom-0 right-0 text-blue-600 bg-white"
      >
        <span class="rotate-45 transform">+</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Lightbox',
  computed: {
    alt() {
      return this.$store.state.lightbox.alt;
    },
    image() {
      return this.$store.state.lightbox.image;
    },
    show() {
      return this.$store.state.lightbox.show;
    },
  },
  methods: {
    close() {
      this.$store.commit('closeLightbox');
    },
  },
};
</script>
