<template>
  <div class="relative">
    <div class="w-full" style="height: calc(60vh + 3.5rem);">
      <div class="w-full h-full bg-white animate-pulse">
        <div class="flex">
          <div class="py-3 xl:py-4 px-6 xl:px-8
                    bg-gray-100 text-sm xl:text-base font-medium
                      whitespace-nowrap shadow-md transition-colors"
          >
            <div class="rounded-full w-16 h-4 bg-gray-100" />
          </div>

          <div class="py-3 xl:py-4 px-6 xl:px-8
                    bg-gray-100 text-sm xl:text-base font-medium
                      whitespace-nowrap shadow-md transition-colors"
          >
            <div class="rounded-full w-20 h-4 bg-gray-100" />
          </div>

          <div class="py-3 xl:py-4 px-6 xl:px-8
                    bg-gray-100 text-sm xl:text-base font-medium
                      whitespace-nowrap shadow-md transition-colors"
          >
            <div class="rounded-full w-16 h-4 bg-gray-100" />
          </div>

          <div class="py-3 xl:py-4 px-6 xl:px-8
                    bg-gray-100 text-sm xl:text-base font-medium
                      whitespace-nowrap shadow-md transition-colors"
          >
            <div class="rounded-full w-16 h-4 bg-gray-100" />
          </div>

          <div class="py-3 xl:py-4 px-6 xl:px-8
                    bg-gray-100 text-sm xl:text-base font-medium
                      whitespace-nowrap shadow-md transition-colors"
          >
            <div class="rounded-full w-16 h-4 bg-gray-100" />
          </div>
        </div>

        <div class="relative z-10 w-full p-5 xl:p-8 bg-white">
          <div class="inline-block rounded-full w-1/2 h-4 bg-gray-100 mb-3 xl:mb-5" />

          <div class="flex flex-wrap gap-2 w-3/4">
            <div class="inline-block rounded-full w-20 h-4 bg-gray-100" />
            <div class="inline-block rounded-full w-24 h-4 bg-gray-100" />
            <div class="inline-block rounded-full w-16 h-4 bg-gray-100" />
            <div class="inline-block rounded-full w-32 h-4 bg-gray-100" />
            <div class="inline-block rounded-full w-36 h-4 bg-gray-100" />
            <div class="inline-block rounded-full w-20 h-4 bg-gray-100" />
            <div class="inline-block rounded-full w-24 h-4 bg-gray-100" />
            <div class="inline-block rounded-full w-10 h-4 bg-gray-100" />
            <div class="inline-block rounded-full w-48 h-4 bg-gray-100" />
            <div class="inline-block rounded-full w-56 h-4 bg-gray-100" />
            <br>
            <br>
            <div class="inline-block rounded-full w-24 h-4 bg-gray-100" />
            <div class="inline-block rounded-full w-16 h-4 bg-gray-100" />
            <div class="inline-block rounded-full w-32 h-4 bg-gray-100" />
            <div class="inline-block rounded-full w-36 h-4 bg-gray-100" />
            <div class="inline-block rounded-full w-20 h-4 bg-gray-100" />
            <div class="inline-block rounded-full w-24 h-4 bg-gray-100" />
            <div class="inline-block rounded-full w-10 h-4 bg-gray-100" />
            <div class="inline-block rounded-full w-16 h-4 bg-gray-100" />
            <div class="inline-block rounded-full w-32 h-4 bg-gray-100" />
            <div class="inline-block rounded-full w-36 h-4 bg-gray-100" />
            <div class="inline-block rounded-full w-20 h-4 bg-gray-100" />
            <div class="inline-block rounded-full w-24 h-4 bg-gray-100" />
            <div class="inline-block rounded-full w-10 h-4 bg-gray-100" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutUsSkeleton',
};
</script>
