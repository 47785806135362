<template>
  <swiper
    v-if="pageCount"
    ref="swiper"
    :slides-per-view="1"
    :space-between="0"
    :speed="600"
    :pagination="false"
    :direction="direction"
    :nested="nested"
    :simulate-touch="simulateTouch"
    :allow-touch-move="allowTouchMove"
    @init="onInit"
    @touchEnd="touchEnd"
    class="content-loader-height pb-0"
  >
    <swiper-slide
      v-for="(_, index) in slides"
      :key="index"
    >
      <component
        v-if="loading || index !== swiper?.activeIndex"
        :is="contentComponent"
        :items-count="itemsCount"

      />
      <slot v-else />
    </swiper-slide>
  </swiper>
  <!-- <div v-else>
    <component
        v-if="loading"
        :is="contentComponent"
        :items-count="itemsCount"
      />

      <slot v-else />
  </div> -->
</template>

<script>
import { contentLoaderTypes } from '@/constants';
import {
  ProductCardSkeleton,
  ProjectCardSkeleton,
  ProductDetailsSkeleton,
  AboutUsSkeleton,
} from './placeholders';

const componentList = {
  [contentLoaderTypes.productCard]: ProductCardSkeleton,
  [contentLoaderTypes.projectCard]: ProjectCardSkeleton,
  [contentLoaderTypes.productDetails]: ProductDetailsSkeleton,
  [contentLoaderTypes.aboutUs]: AboutUsSkeleton,
};

function touchCapable() {
  return (
    'ontouchstart' in window
    || (window.DocumentTouch && document instanceof window.DocumentTouch)
    || navigator.maxTouchPoints > 0
    || window.navigator.msMaxTouchPoints > 0
  );
}

export default {
  name: 'ContentLoader',
  components: {
    ProductCardSkeleton,
  },
  props: {
    allowTouchMove: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: contentLoaderTypes.productCard,
    },
    process: {
      type: Promise,
      default: null,
    },
    itemsCount: {
      type: Number,
      default: 8,
    },
    direction: {
      type: String,
      default: 'horizontal',
    },
    pageCount: {
      type: Number,
      default: 0,
    },
    page: {
      type: Number,
      default: 0,
    },
    nested: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      swiper: null,
      loading: false,
      componentList,
      directions: {
        next: 'next',
        prev: 'prev',
      },
      slides: Array.from({ length: 5 }),
      simulateTouch: !touchCapable(),
    };
  },
  computed: {
    contentComponent() {
      return componentList[this.type];
    },
  },
  watch: {
    process: {
      handler(val) {
        if (!(val instanceof Promise)) return;
        this.loading = true;

        val.finally(() => {
          if (this.swiper) {
            // eslint-disable-next-line no-nested-ternary
            const slide = this.page === 1
              ? 0 : this.page === this.pageCount
                ? 4 : 2;

            this.moveToSlide(slide, false);
          }
          this.loading = false;
        });
      },
    },
    page: {
      handler(newVal, oldVal) {
        if (!oldVal || newVal === oldVal) return;
        const direction = newVal > oldVal
          ? this.directions.next : this.directions.prev;

        const slide = direction === this.directions.next
          // eslint-disable-next-line no-unsafe-optional-chaining
          ? this.swiper?.activeIndex + 1
          // eslint-disable-next-line no-unsafe-optional-chaining
          : this.swiper?.activeIndex - 1;
        this.moveToSlide(slide, true);
      },
    },
  },
  methods: {
    onInit(swiper) {
      this.swiper = swiper;
    },
    moveToSlide(slide, isWithAnimation) {
      this.swiper?.slideTo(slide, isWithAnimation ? 600 : 0);
    },
    touchEnd(swiper) {
      if (swiper.swipeDirection) {
        this.$emit(swiper.swipeDirection);
      }
    },
  },
};
</script>
