<template>
  <div
    class="w-full grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-3 px-4 items-center"
  >
  <!-- <ul class="flex flex-wrap -my-1">
    <li
      v-for="index in itemsCount"
      :key="index"
      class="w-1/4 p-1"
    > -->
      <div
        v-for="index in itemsCount"
        :key="index"
        role="status"
        class="rounded bg-white shadow-md animate-pulse"
      >
        <span class="sr-only">Loading...</span>

        <div class="p-2">
          <div class="relative w-full h-32 bg-gray-100">
            <img
              src="@/assets/images/img-skeleton.svg"
              alt="Project Placeholder"
              class="absolute top-1/2 left-1/2 w-12 h-12"
              style="transform: translate(-50%, -50%)"
            >
          </div>

          <div class="w-full my-4 pb-4">
            <div class="rounded-full w-3/4 h-2 xl:h-3 bg-gray-100 mb-1 xl:mb-2" />
            <div class="rounded-full w-5/6 h-3 xl:h-3 bg-gray-100 mb-1 xl:mb-2" />
            <div class="hidden xl:block rounded-full w-2/3 h-2 xl:h-3 bg-gray-100" />
          </div>

          <div
            class="rounded-full bg-gray-100"
            style="height: 1.475rem; max-width: 8rem;"
          />
        </div>

        <div class="flex items-center justify-between gap-x-2
                    bg-gray-100 p-1 xl:p-2 mt-2">
          <div class="inline-flex items-center gap-x-2 h-8 py-2 px-3">
            <div class="rounded-full w-5 h-3 bg-gray-100" />
            <div class="rounded-full w-9 h-3 bg-gray-100" />
          </div>

          <div class="w-4 h-4 rounded-full bg-gray-100" />
        </div>
      </div>
    <!-- </li>
  </ul> -->
  </div>
</template>

<script>
export default {
  name: 'ProjectCardSkeleton',
  props: {
    itemsCount: {
      type: Number,
      required: true,
    },
  },
};
</script>
