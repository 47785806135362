const home = {
  index: 'home.index',
};

const myCollection = {
  index: 'my-collection.index',
};

const aboutUs = {
  index: 'about-us.index',
};

const projects = {
  index: 'projects.index',
  details: 'projects.details',
};

const products = {
  index: 'products.index',
  details: 'products.details',
};

const productDiscovery = {
  index: 'product-discovery.index',
};

const notFound = {
  index: 'not-found.index',
};

export {
  home,
  myCollection,
  aboutUs,
  projects,
  products,
  productDiscovery,
  notFound,
};
