<template>
  <v-select
    multiple
    deselectFromDropdown
    clearSearchOnSelect
    :closeOnSelect="false"
    v-model="filterModel"
    :label="label"
    :options="options"
    :searchable="false"
    :placeholder="placeholder"
    :class="mainClass"
  >
    <template #selected-option-container>
      <span
        class="vs__selected"
      >
        {{ selectedText }}
      </span>
      <div class="vs__actions" @click.stop="clearFilter">
        <button
          type="button"
          title="Clear Selected"
          aria-label="Clear Selected"
          class="vs__clear"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10">
            <path d="M6.895455 5l2.842897-2.842898c.348864-.348863.348864-.914488
              0-1.263636L9.106534.261648c-.348864-.348864-.914489-.348864-1.263636 0L5 3.104545
              2.157102.261648c-.348863-.348864-.914488-.348864-1.263636
              0L.261648.893466c-.348864.348864-.348864.914489
              0 1.263636L3.104545 5 .261648 7.842898c-.348864.348863-.348864.914488 0
              1.263636l.631818.631818c.348864.348864.914773.348864 1.263636 0L5 6.895455l2.842898
              2.842897c.348863.348864.914772.348864 1.263636
              0l.631818-.631818c.348864-.348864.348864-.914489
              0-1.263636L6.895455 5z">
            </path>
          </svg>
        </button>
      </div>
    </template>

    <template #option="option">
      <div
        class="flex justify-between items-center gap-2"
      >

        <!-- <span
          class="absolute top-1/2 left-0 transform -translate-y-1/2
                border border-gray-300 w-4 h-4"
          :style="{
            'background-color': option.color
              ? `#${colorHelper.getHexColor(option.color)}`: 'transparent'
          }"
        >
        </span>-->
        <span
          class="text-xs"
          :class="{
            'text-blue-500 font-bold': filterModelIds.includes(option.id)
          }"
        >
          {{ option.name }}
        </span>
        <span class="inline-block bg-gray-100 text-xxs font-semibold rounded p-1">
          {{ option.count }}
        </span>
      </div>
    </template>
  </v-select>
</template>

<script>
import { colorHelper } from '@/utils';

export default {
  name: 'FilterComponent',
  props: {
    value: {
      type: Array,
      require: true,
    },
    options: {
      type: Array,
      require: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      require: true,
    },
    mainClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      colorHelper,
      filterModel: [],
    };
  },
  computed: {
    selectedText() {
      return this.filterModel?.length > 1
        ? `${this.filterModel.length} selected`
        : '1 selected';
    },
    filterModelIds() {
      return this.filterModel?.length
        ? this.filterModel.map((value) => value.id)
        : [];
    },
  },
  watch: {
    value: {
      handler(val) {
        this.filterModel = val;
      },
      immediate: true,
      deep: true,
    },
    filterModel: {
      handler(val) {
        this.$emit('input', val);
      },
      deep: true,
    },
  },
  methods: {
    clearFilter() {
      this.filterModel = [];
    },
  },
};
</script>
