import { myCollection } from '@/constants/route-names';

const component = () => import('./my-collection.view.vue');

export default [
  {
    path: '/my-collection',
    name: myCollection.index,
    component,
    meta: {
      title: 'My Collection',
      transition: 'slide-fade',
    },
  },
];
