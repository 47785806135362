function getOptions(optionKey, ids) {
  return ids?.length
    ? this[optionKey].filter((option) => ids.includes(option.id.toString()))
    : null;
}

function getRequestParams(
  categoryId,
  productLines,
  page,
  pageSize,
  projectId,
) {
  return {
    category_ids: [categoryId],
    product_line_ids: productLines?.map((productLine) => productLine.id),
    page,
    per_page: pageSize,
    project_id: projectId,
  };
}

function getQueryModel(filterModel, page, pageSize) {
  return {
    categoryId: filterModel.categoryId,
    productLineIds: filterModel.productLines?.map((productLine) => productLine.id),
    page,
    pageSize,
  };
}

function getIdsFromObject(object) {
  return Object.keys(object).map((id) => +id);
}

function getAvailableOptions(
  currentOptions,
  fromApiFilterOptions,
  allOptions,
  currentFilterOptions,
) {
  if (fromApiFilterOptions) {
    const filterOptionIds = getIdsFromObject(fromApiFilterOptions);
    const options = allOptions.reduce((result, option) => {
      const filterOptionId = filterOptionIds.find((id) => option.id === id);
      if (filterOptionId) {
        result.push({ ...option, count: fromApiFilterOptions[filterOptionId] });
        return result;
      }
      const currentOption = currentFilterOptions?.find(
        (current) => current.id === option.id,
      );
      if (currentOption) {
        result.push(currentOption);
      }
      return result;
    }, []);
    return options.length === currentOptions?.length
      ? currentOptions
      : options;
  }
  return allOptions;
}

export default {
  getOptions,
  getQueryModel,
  getRequestParams,
  getIdsFromObject,
  getAvailableOptions,
};
