<template>
  <div class="flex justify-center">
    <div class="relative">
      <paginate
        :margin-pages="0"
        v-model="page"
        :page-count="pageCount"
        :page-range="pageSize"
        :click-handler="handlePageChange"
        prev-text=""
        next-text=""
        :container-class="`pagination pagination--${orientation}`"
        page-class="pagination__item"
        page-link-class="pagination__link"
        prev-link-class="pagination__prev"
        :next-link-class="`pagination__next ${(lastPages && showJumpButton) && 'last'}`"
      />

      <div
        v-if="showJumpButton"
        v-on-click-away="close"
        class="pagination__item pagination__item--jump"
        :class="{
          'show': showPopup,
          'last': lastPages
        }"
        @click="openPopup"
      >
        <div class="pagination__link">
          ...
        </div>

        <ul class="pagination__popup">
          <li>Jump to</li>
          <li
            v-for="option in options"
            :key="option"
            @click.stop="jumpToPage(option)"
          >
            {{ option }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { directive as onClickAway } from 'vue-clickaway';

export default {
  name: 'Pagination',
  directives: {
    onClickAway,
  },
  props: {
    orientation: {
      type: String,
      default: 'horizontal',
    },
    value: {
      type: Number,
      required: true,
    },
    pageCount: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      page: 1,
      showPopup: false,
    };
  },
  watch: {
    value: {
      handler(val) {
        this.page = val;
      },
      immediate: true,
    },
    page: {
      handler(val) {
        this.$emit('input', val);
      },
      immediate: true,
    },
  },
  computed: {
    options() {
      const middleOption = 1 + Math.ceil((this.pageCount - 1) / 2);
      return [1, middleOption, this.pageCount];
    },
    lastPages() {
      const limit = Math.floor(this.pageSize / 2);
      // console.log(this.pageCount - limit);
      return this.page > this.pageCount - limit;
    },
    showJumpButton() {
      return this.pageCount > 3;
    },
  },
  methods: {
    handlePageChange(page) {
      this.$emit('changePage', page);
    },
    openPopup() {
      this.showPopup = true;
    },
    jumpToPage(page) {
      this.page = page;
      this.handlePageChange(page);
      this.close();
    },
    close() {
      this.showPopup = false;
    },
  },
};
</script>
