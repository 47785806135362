function getRequestParams(
  categoryId,
  subCategories,
  applicationTypes,
  countries,
  page,
  pageSize,
  productId,
) {
  return {
    category_ids: [categoryId],
    sub_category_ids: subCategories?.map((category) => category.id),
    application_type_ids: applicationTypes?.map((applicationType) => applicationType.id),
    country_ids: countries?.map((country) => country.id),
    page,
    per_page: pageSize,
    product_id: productId,
  };
}

function getQueryModel(filterModel, page, pageSize) {
  return {
    categoryId: filterModel.categoryId,
    subCategoryIds: filterModel.subCategories?.map((subCategory) => subCategory.id),
    applicationTypeIds: filterModel.applicationTypes?.map((applicationType) => applicationType.id),
    countryIds: filterModel.countries?.map((country) => country.id),
    page,
    pageSize,
  };
}

function getOptions(optionKey, ids) {
  return ids?.length
    ? this[optionKey].filter((option) => ids.includes(option.id.toString()))
    : null;
}

function getIdsFromObject(object) {
  return Object.keys(object).map((id) => +id);
}

function getAvailableOptions(
  currentOptions,
  fromApiFilterOptions,
  allOptions,
  currentFilterOptions,
) {
  if (fromApiFilterOptions) {
    const filterOptionIds = getIdsFromObject(fromApiFilterOptions);
    const options = allOptions.reduce((result, option) => {
      const filterOptionId = filterOptionIds.find((id) => option.id === id);
      if (filterOptionId) {
        result.push({ ...option, count: fromApiFilterOptions[filterOptionId] });
        return result;
      }
      const currentOption = currentFilterOptions?.find(
        (current) => current.id === option.id,
      );
      if (currentOption) {
        result.push(currentOption);
      }
      return result;
    }, []);
    return options.length === currentOptions?.length
      ? currentOptions
      : options;
  }
  return allOptions;
}

export default {
  getOptions,
  getRequestParams,
  getQueryModel,
  getIdsFromObject,
  getAvailableOptions,
};
